@use '../../styles/variables' as variables;

.root {
    height: 72px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: variables.$color-light-gray;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);

    > :not(:last-child) {
        margin-right: 1rem;
    }
}

.controlGroup {
    height: 56px;

    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
        position: relative;

        height: inherit;
        padding: 0 1rem;

        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-child) {
            border-right: 1px rgba(0, 0, 0, 0.12) solid;
        }
    }
}

.actionGroup {
    > :not(:last-child) {
        margin-right: 1rem;
    }
}

.controlIcon {
    margin: 0 1em;
}

.printIcon {
    min-width: 34px;
    width: 34px;
    height: 34px;
    padding: 0.25rem;
}

.selectIcon {
    margin-top: 0.25rem;
}

.selectType {
    min-width: 220px;
}
