@use '../../styles/variables' as vars;

$content-padding-y: 8px;

.root {
    min-height: 52px;
    padding-top: $content-padding-y;
    padding-bottom: $content-padding-y;

    display: flex;

    color: vars.$color-text-secondary;

    :global {
        .MuiSvgIcon-root {
            width: 28px;
            height: auto;
            margin-right: 4px;
        }
    }
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
