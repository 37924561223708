@use '../../styles/variables' as variables;

.root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    min-height: variables.$header-height;
    background-color: variables.$color-gray-dark;
    padding: 0 16px;
    box-shadow: none;
}

.buttonsGroup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.button {
    margin-right: 16px;
}

.heading {
    @media (max-width: variables.$breakpoint-md) {
        display: none;
    }
}
