@use '../../styles/variables' as variables;

.root {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: variables.$color-light-gray-dark;
    font-family: 'Noto Serif HK', 'Times New Roman', serif;
}

.paper {
    border: 2px solid variables.$color-gray-darkest;
}

.loadingSpinner {
    $spinner-size: 96px;

    position: absolute;
    top: calc(50% - #{$spinner-size}/2);
    left: calc(50% - #{$spinner-size}/2);
}

.workspaceContainer {
    position: relative;
}
