// Colors
$color-gray-darkest: #1a202e;
$color-gray-dark: #242a38;
$color-gray: #4c5260;
$color-gray-light: #424856;

$color-light-gray: #fdfdfd;
$color-light-gray-dark: #fafafa;
$color-light-gray-darkest: hsl(0, 0%, 93%); // darkest, kek

$color-primary: #6200ee;

$color-text-secondary: rgba(0, 0, 0, 0.48);

// Font families
$font-family-base: 'Roboto', sans-serif;

// Font weights
$font-weight-regular: 400;
$font-weight-bold: 700;

// Breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

$header-height: 56px;
