@use '../../styles/variables' as vars;

.closeButton {
    position: absolute;
    top: 12px;
    right: 12px;
}

.dialogTitle {
    position: relative;
    padding-top: 24px;
    padding-bottom: 8px;

    :global {
        .MuiTypography-root {
            font-size: 16px;
        }
    }
}

.dialogContent {
    width: 280px;
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;

    :global {
        .MuiTypography-body1 {
            font-size: 14px;
            margin-bottom: 0;
        }
    }
}

.dialogActions {
    padding: 8px;
    display: flex;
    justify-content: flex-end;
}
