@use 'variables' as vars;
@use 'functions' as func;

* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
button {
    font-family: vars.$font-family-base;
}

body {
    margin: 0;
    min-height: 100vh;
    letter-spacing: 0;
    font-weight: vars.$font-weight-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    [hidden] {
        display: none;
    }
}
