@use '../../styles/variables' as variables;

.link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 72px;
    color: rgba(white, 0.74);
    background-color: variables.$color-gray;
    text-decoration: none;
    transition-property: color, background-color;
    transition: 0.25s ease-in-out;

    &.active,
    &:hover {
        color: rgba(white, 0.9);
        background-color: variables.$color-gray-dark;
    }
}

.label {
    padding-top: 12px;
    font-size: 11px;
    letter-spacing: 0.25px;
}

:global {
    .MuiDrawer-paper {
        background-color: variables.$color-gray-light;
        height: calc(100% - #{variables.$header-height} + 2px);
        width: 72px;
        bottom: 0;
        top: auto;
    }
}
