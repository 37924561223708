@use '../../styles/variables' as variables;

.root {
    position: relative;
    min-height: 400px;
}

.headTail {
    position: absolute;
    background-color: variables.$color-light-gray;
    box-shadow: inset 0px -1px 0px rgba(black, 0.08);
    width: calc(100% + 400px);
    left: -200px;
    top: 0;
    z-index: 0;
}

.tableWrapper {
    position: relative;
    z-index: 1;
}
