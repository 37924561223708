@use '../../styles/variables' as variables;

.root {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: variables.$color-gray-dark;
    min-height: 100vh;
    padding-left: 16px;
    padding-right: 16px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    max-width: 100%;
    width: 414px;
    margin-bottom: 75px;

    @media (max-width: variables.$breakpoint-md) {
        width: 350px;
        margin-bottom: 50px;
    }

    @media (max-width: variables.$breakpoint-sm) {
        margin-bottom: 32px;
        width: 250px;
    }
}
