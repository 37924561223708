@use '../../styles/variables' as vars;

$col-padding-y: 8px;

.row {
    background-color: vars.$color-light-gray-darkest;
    box-shadow: none;
}

.col {
    padding-top: $col-padding-y;
    padding-bottom: $col-padding-y;
}

.root {
    font-weight: 400;
    display: flex;
    align-items: center;
}

.button {
    margin-left: 8px;
    color: vars.$color-primary;
}
