@use '../../styles/variables' as variables;

.root {
    position: relative;

    &:after {
        position: absolute;
        background-color: variables.$color-light-gray;
        box-shadow: inset 0px -1px 0px rgba(black, 0.08);
        content: '';
        width: calc(100% + 400px);
        left: -200px;
        top: 0;
        z-index: 0;
        height: 56px;
    }
}

.tableContainer {
    position: relative;
    z-index: 1;
}

.labelWrapper {
    display: flex;
    align-items: center;
    min-width: 360px;
}

.head {
    .cellInner {
        white-space: nowrap;
    }
}

.cell {
    &:not(:first-child) {
        padding: 6px 0;

        .cellInner {
            padding: 10px 16px;
            box-shadow: inset 1px 0px 0px rgba(black, 0.08);
        }
    }

    &.gray {
        color: rgba(black, 0.48);
    }
}

.center {
    text-align: center;
}

.button {
    transition: opacity 0.25s ease-in-out;
}

.hidden {
    opacity: 0;
    pointer-events: none;
}

.editButton {
    margin-left: 10px;
    min-width: 38px;
    width: 38px;
    height: 36px;
    padding: 0;
}

.actionButtonsWrapper {
    display: flex;
    margin-left: auto;
}

.fabSaveChanges {
    position: fixed;
    bottom: 20px;
    right: 20px;

    :global {
        .MuiSvgIcon-root {
            margin-right: 8px;
        }
    }
}

.loader {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &Row {
        background-color: variables.$color-light-gray-darkest;
        box-shadow: none;
    }
}
