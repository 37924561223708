@use '../../styles/variables' as variables;

.root {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.button {
    min-width: 180px;
    margin-top: 32px;
}

.controls {
    display: flex;
    flex-direction: column;
}

.control {
    width: 301px;

    @media (max-width: variables.$breakpoint-sm) {
        width: 100%;
    }

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    &.autofilled {
        .iconButton,
        .label {
            color: variables.$color-gray-dark;
        }

        .iconButton {
            &:hover {
                color: variables.$color-primary;
            }
        }

        .input {
            &:autofill {
                background-color: variables.$color-light-gray;
                color: variables.$color-gray-dark;
            }
        }
    }
}

.inputWithIcon {
    padding-right: 56px;
}

.iconButton {
    position: absolute;
    right: 6px;
    height: 100%;
    color: white;
    background-color: transparent;
    border: none;
    transition: color 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
        color: variables.$color-primary;
    }
}
