@use '../../styles/variables' as variables;

.root {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
}

.main {
    display: flex;
    flex-grow: 1;
}

.content {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    padding: 0 201px;
    background-color: variables.$color-light-gray-darkest;

    @media (max-width: variables.$breakpoint-lg) {
        padding: 0 24px;
    }

    @media (max-width: variables.$breakpoint-md) {
        padding: 32px;
    }

    @media (max-width: variables.$breakpoint-sm) {
        padding: 16px;
    }

    &.withSidebar {
        @media (max-width: variables.$breakpoint-md) {
            padding-left: 104px;
        }

        @media (max-width: variables.$breakpoint-sm) {
            padding-left: 88px;
        }
    }
}

.heading {
    margin-bottom: 16px;

    @media (min-width: variables.$breakpoint-md) {
        display: none;
    }
}
