@use '../../styles/variables' as variables;

.root {
    z-index: 10;
    position: absolute;
    border-radius: 3px;

    padding: 1px;

    border: 1px dashed transparent;

    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Noto Serif HK', 'Times New Roman', serif;

    &:hover:not(.selected) {
        border-color: variables.$color-gray-dark;
    }
}

.selected {
    border-color: variables.$color-primary;
    z-index: 20;
}

.dragging {
    cursor: grabbing;
}

.draggable {
    cursor: grab;
}

.resizable {
}

.resizableArea {
    $resizeArea: 8px;
    content: ' ';
    position: absolute;
    border-radius: 50%;
    right: -($resizeArea / 2);
    bottom: -($resizeArea / 2);
    width: $resizeArea;
    height: $resizeArea;
    cursor: nwse-resize;
    background: red;
}

.menuContainer {
    right: 0;
    position: absolute;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;

    height: 30px;

    background: variables.$color-light-gray-dark;
    border-radius: 5px 5px 0 0;
    z-index: 100;
}

.menuButton {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 4px;
    }
}

.fontSizeSelect {
    margin-right: 4px;
    background-color: white;
}

.boldView {
    font-family: 'Noto Serif SC', 'Times New Roman', serif;
    font-weight: 700;
}
