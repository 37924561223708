@use '../../styles/variables' as variables;

.input {
    z-index: 100;
    margin: 0;
    padding: 0;

    resize: none;
    border: none;

    cursor: text;
    overflow: hidden;

    font-size: inherit;
    font-family: inherit;
    letter-spacing: inherit;

    height: 100%;
    width: 100%;

    &:hover,
    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: transparent;
        color: black;
    }
}

.root {
    align-items: flex-start;
    justify-content: flex-start;
}

.span {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}
