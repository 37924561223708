@use '../../styles/variables' as variables;

.barcodeRoot {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.textRoot {
    align-items: flex-start;
    justify-content: flex-start;
}
